// import { omit } from 'ramda';
import { useToast } from "vue-toastification"
import i18n from "@/i18n"

const formattedOrderSubType = {
  CASHBOX: "cashbox",
  MARKET_EXCHANGE: "(market)",
  LIMIT_EXCHANGE: "(limit)",
  SEPA: "Sepa",
  SWIFT: "Swift",
  GATEWAY: "Gateway"
}

export const orderType = ( order ) => {
  let subtype = order.order_sub_type
  if ( order.order_type ) {
    if ( order.order_type === "WITHDRAWAL" ) {
      if ( subtype ) {
        if ( subtype === "BANK_COLLECTION" ) {
          return i18n.global.tc( "history.orderTypes.withdrawCourier" )
        }
        if ( subtype === "CASHBOX" ) {
          return i18n.global.tc( "history.orderTypes.withdrawCashbox" )
        }
        if ( subtype === "GATEWAY" && helpersIsCrypto( order.currency ) ) {
          // return 'Вывод '+order.currency;
          return `${ i18n.global.tc( "history.orderTypes.withdraw" ) } ${ order.currency }`
        }
        if ( subtype === "GATEWAY" && !helpersIsCrypto( order.currency ) ) {
          return i18n.global.tc( "history.orderTypes.withdrawGateway" )
        }
      }
    } else if ( order.order_type === "DEPOSIT" ) {
      if ( subtype ) {
        if ( subtype === "BANK_COLLECTION" ) {
          return i18n.global.tc( "history.orderTypes.depositCourier" )
        }
        if ( subtype === "CASHBOX" ) {
          return i18n.global.tc( "history.orderTypes.depositCashbox" )
        }
        if ( subtype === "GATEWAY" && helpersIsCrypto( order.currency ) ) {
          // return 'Пополнение '+order.currency;
          return `${ i18n.global.tc( "history.orderTypes.deposit" ) } ${ order.currency }`
        }
        if ( subtype === "GATEWAY" && !helpersIsCrypto( order.currency ) ) {
          return i18n.global.tc( "history.orderTypes.depositGateway" )
        }
      }
    } else if ( order.order_type === "EXCHANGE" ) {
      if ( subtype === "MARKET_EXCHANGE" ) {
        return i18n.global.tc( "history.orderTypes.market" )
      }
      if ( subtype === "LIMIT_EXCHANGE" ) {
        return i18n.global.tc( "history.orderTypes.limit" )
      }
    } else if ( order.order_type === "INTERNAL_MOVEMENT" ) {
      return i18n.global.tc( "history.orderTypes.transfer" )
    } else if ( order.order_type === "INVOICE" ) {
      return i18n.global.tc( "history.orderTypes.invoice" )
    }
  }
  return ""
}

export const orderTypeForXLSX = ( type ) => {
  switch ( type ) {
  case "WITHDRAWAL":
    return i18n.global.tc( "global.xlsx.withdrawal" )
  case "DEPOSIT":
    return i18n.global.tc( "global.xlsx.deposit" )
  case "EXCHANGE":
    return i18n.global.tc( "global.xlsx.exchange" )
  case "INTERNAL_MOVEMENT":
    return i18n.global.tc( "global.xlsx.internalMovement" )
  case "INVOICE":
    return i18n.global.tc( "global.xlsx.invoice" )
  default:
    return ""
  }
}

export const translateStatus = ( status = "" ) => {
  const statuses = {
    "NEW": i18n.global.tc( "history.statusNew" ),
    "WAITING_FOR_OPERATOR_CONFIRMATION": i18n.global.tc( "history.statusWaiting" ),
    "WAITING_FOR_OPERATOR_CONFIRM": i18n.global.tc( "history.statusNew" ),
    "WAITING_FOR_CONFIRMATION": i18n.global.tc( "history.statusWaiting" ),
    "WAITING_FOR_PRICE": i18n.global.tc( "history.priceWaiting" ),
    "CLOSED": i18n.global.tc( "history.statusClosed" ),
    "ERROR": i18n.global.tc( "history.statusError" ),
    "CANCELLED": i18n.global.tc( "history.statusCancel" ),
    "PAYMENT_IN_PROGRESS": i18n.global.tc( "history.statusInProgress" ),
    "CONFIRM": i18n.global.tc( "history.statusConfirm" ),
    "CONFIRMATION": i18n.global.tc( "history.statusConfirm" ),
    "EXPIRED": i18n.global.tc( "history.statusExpired" ),
    "CONFIRMED_BY_OPERATOR": i18n.global.tc( "history.statusWaiting" ),
    "CANCELLING": i18n.global.tc( "history.statusCancelling" )
  }
  if ( Object.keys( statuses )
    .includes( status ) ) {
    return statuses[ status ]
  }
  return status
}

export const helpersIsCrypto = ( currency = "BTC" ) => {
  currency = currency.toUpperCase()
  switch ( currency ) {
  case "BTC":
  case "ETH":
  case "USDT":
  case "DOGE":
  case "LTC":
  case "BNB":
  case "TRX":
  case "ADA":
  case "USDTOMNI":
  case "TRON":
  case "DOT":
  case "MATIC":
  case "XRP":
  case "SOL":
  case "SHIB":
  case "UCB":
  case "UACB-NOV22":
  case "KZT":
  case "USDC":
    return true
  default:
    return false
  }
}

export const helpersGetCurrencyFullName = currency => {
  const names = {
    USDT: "TetherUS",
    USD: "Dollar",
    EUR: "Euro",
    BTC: "Bitcoin",
    ETH: "Ethereum",
    UAH: "Ukraine Hryvnia",
    LTC: "Litecoin",
    USDC: "USD Coin",
    BNB: "Binance Coin",
    DOGE: "Dogecoin"
  }

  return names[currency]
}

export const helpersRoundingCurrency = ( {
  currencyAmount, currency
} ) => {

  const isNumberExponential = String( currencyAmount )
    .toLowerCase()
    .includes( "e-" )
  
  if ( isNumberExponential ) {
    const value = String( currencyAmount )
      .split( "e-" )[ 0 ]
    const pow = Number( String( currencyAmount )
      .split( "e-" )[ 1 ] )
    currencyAmount = currencyAmount < 1e-6
      ? 0
      : `0.${ ( new Array( pow - value.length + 1 ) )
        .join( "0" ) }${ value }`
  }
  
  const isCurrencyHaveDecimal = `${ currencyAmount }`.split( "." )?.[ 1 ]?.length > 2
  
  if ( isCurrencyHaveDecimal ) {
    const currencyAmountParts = `${ currencyAmount }`.split( "." )
    const beforeDecimal = currencyAmountParts[ 0 ]
    const afterDecimal = ( helpersIsCrypto( currency ) && currency != "USDT" )
      ? currencyAmountParts[ 1 ].slice( 0, 6 )
      : currencyAmountParts[ 1 ].slice( 0, 2 )
    currencyAmount = `${ beforeDecimal }.${ afterDecimal }`
  }
  
  const isZero = String( currencyAmount )
    .replaceAll( "0", "" )
    .replace( ".", "" ) === ""
  
  currencyAmount = isZero
    ? 0
    : currencyAmount
  
  return Number( currencyAmount )
}

export const helpersFeeFormatter = ( {
  fees = {}, currency
} ) => {
  const {
    percent_fee, static_fee
  } = fees
  let fee = "-"
  if ( percent_fee && static_fee ) {
    fee = `${ percent_fee }% + ${ static_fee } ${ currency }`
  } else if ( percent_fee && !static_fee ) {
    fee = `${ percent_fee }%`
  } else if ( !percent_fee && static_fee ) {
    fee = `${ static_fee } ${ currency }`
  } else if ( static_fee != undefined || percent_fee != undefined ) {
    fee = `0 ${ currency }`
  }
  return fee
}

export const helpersRemoveEmptyObjectKey = ( obj ) => {
  return Object.fromEntries(
    Object.entries( obj )
      .filter( ( [
        _,
        v 
      ] ) => _ && v != null && v != "" )
      .map( ( [
        k,
        v 
      ] ) => [
        k,
        v === Object( v )
          ? helpersRemoveEmptyObjectKey( v )
          : v
      ] )
  )
}

export const helpersHasClass = ( classNames, classAttr = "" ) => {
  console.log( classNames, classAttr );
  return classNames.find( ( className ) => {
    return ( classAttr.split( " " ) || [] )?.find( ( el ) => el.indexOf( className ) == 0 )
  } )
}

export const helpersStyleStatus = ( value = "" ) => {
  switch ( value.toLowerCase() ) {
  case "closed":
    return [
      "text-white",
      "bg-green-600"
    ]
  case "error":
    return [
      "text-white",
      "bg-red-600"
    ]
  case "waiting":
    return [
      "text-yellow-600",
      "bg-yellow-600/10"
    ]
  case "cancelling":
    return [
      "text-white",
      "bg-black"
    ]
  case "new":
    return [
      "bg-blue-600/10",
      "text-blue-600"
    ]
  case "expired":
    return [
      "bg-red-600/10",
      "text-red-600/50"
    ]
  case "cancelled":
    return [
      "bg-green-600/10",
      "text-green-600"
    ]
  case "blocked":
    return [
      "bg-red-600/[.15]",
      "text-red-600",
      "border",
      "border-red-600"
    ]
  case "completed":
    return [
      "bg-green-600/10",
      "text-green-600"
    ]
  default:
    if ( value.toLowerCase()
      .includes( "waiting" ) ) {
      return [
        "text-yellow-600",
        "bg-yellow-600/10"
      ]
    }
    return [
      "",
      "bg-gray-300"
    ]
  }
}

export const helpersNotification = ( {
  type, message, timeout, closeOnClick
} ) => {
  const toast = useToast()
  toast( message, {
    type,
    timeout,
    closeOnClick 
  } )
}

export const helpersCopyToClipboard = ( {
  value, notificationText = "Copied to clipboard"
} ) => {
  const input = document.createElement( "input" )
  document.body.appendChild( input )
  input.setAttribute( "value", value )
  input.select()
  document.execCommand( "copy" )
  document.body.removeChild( input )
  if ( notificationText ) {
    helpersNotification( {
      type: "success",
      message: notificationText 
    } )
  }
}

export const helpersGetAvailableFundsByOperation = ( {
  balance = {}, fees = {}
} ) => {
  const {
    total = 0, reserved = 0
  } = balance
  const {
    percent_fee = 0, static_fee = 0
  } = fees
  let amount = total - reserved
  if ( percent_fee ) {
    amount = amount * ( 100 - percent_fee ) / 100
  }
  if ( static_fee ) {
    amount = amount - static_fee
  }
  return amount
}

export const formatOrderTypeToHistoryView = order => {
  const {
    order_type, order_sub_type, currency
  } = order
  const formattedOrderType = ( order_type && order_type.toLowerCase() ) || order_type
  if ( order_type === "INVOICE" ) {
    return `${ formattedOrderType } ${ currency }`
  }
  if ( order_type === "INTERNAL_MOVEMENT" ) {
    return "Internal Movement"
  }
  if ( order_sub_type == "GATEWAY" ) {
    if ( order_type == "DEPOSIT" ) {
      const formattedOrderCurrency = helpersIsCrypto( currency )
        ? currency
        : "Visa/<nobr>MasterCard</nobr>"
      return `${ formattedOrderType } ${ formattedOrderCurrency }`
    }
    if ( order_type == "WITHDRAWAL" && helpersIsCrypto( currency ) ) {
      const formattedOrderCurrency = currency
      return `${ formattedOrderType } ${ formattedOrderCurrency }`
    }
  }
  const orderSubTypeToView = formattedOrderSubType[ order_sub_type ] || order_sub_type
  return `${ formattedOrderType } ${ orderSubTypeToView }`
}

export const generateObjectForExportXLSX = ( item, index ) => {
  let amount,
    currency,
    feeCurrency
  const indexInCurrency = item.currency.indexOf( "_" ),
    currencyOperationBuy = item.currency.split( "_" )[ 0 ],
    currencyOperationSell = item.currency.split( "_" )[ 1 ],
    currencyStatus = translateStatus( item.status )

  if ( indexInCurrency === -1 ) {
    amount = item.amount
      ? `${ item.amount }`
      : "-"
    currency = item.currency
      ? `${ item.currency }`
      : "-"
    feeCurrency = item.currency
      ? `${ item.currency }`
      : "-"
  } else {
    const currencyAmount = item.currency.split( "_" )
    amount = item.details.currency_to_get_amount
      ? `${ item.details.currency_to_get_amount }`
      : "-"
    currency = item.details.currency_to_get_amount
      ? `${ currencyAmount[ 0 ] }`
      : "-"
    feeCurrency = item.currency
      ? `${ currencyAmount[ 1 ] }`
      : "-"
  }

  const dataTime = String( item.dt )
    .split( "." )
  // const itemOrderType = formatOrderTypeToHistoryView( item )

  const itemObj = {
    [ i18n.global.tc( "global.xlsx.number" ) ]: index + 1,
    [ i18n.global.tc( "global.xlsx.externalId" ) ]: item.external_id
      ? item.external_id
      : "-",
    [ i18n.global.tc( "global.xlsx.InternalID" ) ]: item.internal_id
      ? item.internal_id
      : "-",
    [ i18n.global.tc( "global.xlsx.Data" ) ]: item.dt
      ? dataTime[ 0 ]
      : "-",
    [ i18n.global.tc( "global.xlsx.TransactionType" ) ]: orderType( item ),
    [ i18n.global.tc( "global.xlsx.Amount" ) ]: amount,
    [ i18n.global.tc( "global.xlsx.Currency" ) ]: currency,
    [ i18n.global.tc( "global.xlsx.Fee" ) ]: item.details.fee
      ? `${ item.details.fee }`
      : item.fee
        ? `${ item.fee }`
        : "0",
    [ i18n.global.tc( "global.xlsx.FeeCurrency" ) ]: feeCurrency,
    [ i18n.global.tc( "global.xlsx.Comment" ) ]: item.details.comment
      ? item.details.comment
      : "-",
    [ i18n.global.tc( "global.xlsx.Status" ) ]: currencyStatus,
    [ i18n.global.tc( "global.xlsx.TransactionHash" ) ]: item.details.tr_hash
      ? item.details.tr_hash
      : "-"
  }

  return [
    itemObj,
    currencyOperationBuy,
    currencyOperationSell
  ]
}

export const helpersUpdateToken = ( token, time ) => {
  localStorage.setItem( "user_token", token )
  localStorage.setItem( "user_token_time", time )
}