export default { 
  EN: {
    "user/create": "We have sent a letter to your email address. Please use the instructions in this email to complete your registration.",
    "user/change_password": "Password changed successfully",
    "user/enable_2fa": "Google authentication was successfully enabled",
    "user/disable_2fa": "Google authentication was successfully disabled",
    "user/api_keys/create": "An email with a confirmation code has been sent to your email",
    "user/api_keys/verify": "API key successfully created",
    "user/api_keys/delete": "API key successfully deleted",    
    "user/white_ip/create": "IP successfully added",
    "user/white_ip/delete": "IP successfully deleted",
    "user/reset_password/send_link": "Password recovery link has been sent to your email",
    "user/reset_password/set_new_password": "Password updated successfully",
    
    "withdrawal": "Withdrawal successfully created",
    "withdrawal/cancel": "Withdrawal canceled successfully",
    "withdrawal/repeat": "Withdrawal successfully repeated",
    "withdrawal/request/create": "An email with a confirmation code has been sent to your email",
    "exchange": "Exchange order successfully created",
    "exchange/cancel": "Exchange canceled successfully",
    "exchange/repeat": "Exchange successfully repeated",
    "invoice/pay": "Invoice paid successfully",
    "invoice/pay/public": "Invoice paid successfully",
    "invoice/": "Invoice has been successfully issued",
    "internal_movement": "Internal Movement successfully created",
    "internal_movement/repeat": "Internal movement successfully repeated",
    error: {
      "user/refresh_token": "The current session has expired, please re-login",
    },
  },
}

