<template>
  <div
    class="fixed z-[100] w-full left-0 top-0 border-b border-gray-300 bg-white"
    :class="{
      'shadow-[0px_18px_21px_rgba(34,_39,_47,_0.34)]': isWindowTopOffset,
    }"
  >
    <BaseContainer>
      <div class="flex items-center justify-between h-56 md:h-[74px] py-10">
        <router-link :to="{name: 'DashboardPage'}">
          <img
            class="h-32 md:h-48 w-auto"
            src="@/assets/images/company-logo.svg"
            alt="company"
          >
        </router-link>
        <div class="hidden lg:flex items-center gap-10 xl:gap-16">
          <a
            v-for="menuItem, index in menuItems"
            :key="index"
            class="flex items-center justify-center text-center h-56 px-10 xl:px-16 py-6 text-18 font-medium text-inherit hover:text-green-600"
            :href="menuItem.link"
          >{{ menuItem.label }}</a>
        </div>
        <div
          class="xl:hidden w-20 h-24 relative cursor-pointer"
          @click="isMobileMenuOpen = !isMobileMenuOpen"
        >
          <div
            class="absolute w-14 h-2 left-0 top-1/2 -translate-y-1/2"
            :class="{
              'bg-green-600': !isMobileMenuOpen
            }"
          >
            <div
              class="absolute h-2 left-0"
              :class="{
                'w-10 bottom-6 bg-gray-600': !isMobileMenuOpen,
                'w-20 rotate-45 bottom-0 bg-green-600': isMobileMenuOpen,
              }"
            />
            <div
              class="absolute w-20 h-2 left-0"
              :class="{
                'top-6 bg-gray-600': !isMobileMenuOpen,
                '-rotate-45 top-0 bg-green-600': isMobileMenuOpen,
              }"
            />
          </div>
        </div>
      </div>
    </BaseContainer>
  </div>
  <div class="h-56 md:h-[74px]" />
</template>

<script>
import { mapWritableState } from "pinia"
import { useNavigationHeader } from "@/stores/navigationHeader"
import BaseContainer from "@/components/UI/BaseContainer.vue"
export default {
  name: "NavigationHeader",
  components: {
    BaseContainer,
  },
  data() {
    return {
      isWindowTopOffset: false
    }
  },
  computed: {
    ...mapWritableState( useNavigationHeader, [
      "menuItems",
      "isMobileMenuOpen", 
    ] ),
  },
  created () {
    window.addEventListener( "scroll", this.handleScroll );
  },
  unmounted () {
    window.removeEventListener( "scroll", this.handleScroll );
  },
  methods: {
    handleScroll () {
      this.isWindowTopOffset = document.documentElement.scrollTop || document.body.scrollTop
        ? true
        : false
    }
  }
};
</script>
