import { defineStore } from "pinia"

export const useNavigationHeader = defineStore( "navigationHeader", {
  state: () => ( {
    menuItems: [ 
      {
        label: "Buy Crypto",
        link: "#",
      },
      {
        label: "Exchange",
        link: "https://cryptoexchange.com/soon",
      },
      {
        label: "Escrow",
        link: "https://cryptoexchange.com/escrow",
      },
      {
        label: "Marketplace",
        link: "https://cryptoexchange.com/marketplace",
      },
      {
        label: "Learning Center",
        link: "https://cryptoexchange.com/marketplace",
      },
    ],
    isMobileMenuOpen: false,
  } ),
} )