import { defineStore } from "pinia"

export const useNavigationFooter = defineStore( "navigationFooter", {
  state: () => ( {
    menuItems: [ 
      {
        label: "Company",
        children: [
          {
            label: "About",
            link: "https://cryptoexchange.com/about",
          },
          {
            label: "Licenses",
            link: "https://cryptoexchange.com/licenses",
          },
          {
            label: "Terms of Service",
            link: "https://cryptoexchange.com/terms-of-service",
          },
          {
            label: "Privacy Policy",
            link: "https://cryptoexchange.com/privacy",
          },
          {
            label: "Our Team",
            link: "https://cryptoexchange.com/team",
          },
          {
            label: "Contact Us",
            link: "https://cryptoexchange.com/support",
          },
          {
            label: "FAQs",
            link: "https://cryptoexchange.com/faq",
          },
        ]
      },
      {
        label: "Learning Center",
        link: "https://cryptoexchange.com/learning-center",
        children: [
          {
            label: "What is Crypto?",
            link: "https://cryptoexchange.com/learning/what-is-cryptocurrency",
          },
          {
            label: "What is Bitcoin?",
            link: "https://cryptoexchange.com/learning/what-is-bitcoin",
          },
          {
            label: "What is Blockchain?",
            link: "https://cryptoexchange.com/learning/what-is-blockchain",
          },
          {
            label: "What is Ethereum?",
            link: "https://cryptoexchange.com/learning/what-is-ethereum",
          },
          {
            label: "What is Litecoin?",
            link: "https://cryptoexchange.com/learning/what-is-litecoin",
          },
          {
            label: "Buy Ethereum",
            link: "#",
          },
          {
            label: "Buy XRP",
            link: "#",
          },
          {
            label: "Supported countries",
            link: "https://cryptoexchange.com/supported-countries",
          },
          {
            label: "Developer API",
            link: "https://cryptoexchange.com/integrations",
          },
        ]
      },
      {
        label: "Escrow Services",
        children: [
          {
            label: "Vehicle Escrow",
            link: "https://cryptoexchange.com/escrow/help/buy-sell-cars",
          },
          {
            label: "NFT Escrow",
            link: "https://cryptoexchange.com/escrow/help/nft-escrow-services",
          },
          {
            label: "Domain And Website Escrow",
            link: "https://cryptoexchange.com/escrow/help/buy-sell-domains",
          },
          {
            label: "Domain Name Holding Escrow",
            link: "https://cryptoexchange.com/escrow/help/buy-sell-domains",
          },
        ]
      },
    ]
  } ),
} )