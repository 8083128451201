import { api } from "@/api"
import { helpersUpdateToken } from "@/utils/helpers"
import router from "@/router"

export default class User {
  static createAccount( payload ) {
    return api.post( "user/create", payload )
  }

  static async authorizationObtainToken( payload ) {
    let response = await api.post( "user/obtain_token", payload )
    helpersUpdateToken( response.data.token, Date.now() )
    localStorage.setItem( "isAuth", "true" )
    return response
  }

  static async totpVerify( payload ) {
    let response = await api.post( "user/totp/verify", payload )
    helpersUpdateToken( response.data.token, Date.now() )
  }

  static getBalance() {
    return api.get( "user/balance" )
  }

  static getAccountInfo() {
    return api.get( "user/account_info" )
  }

  static changePassword( payload ) {
    return api.post( "user/change_password", payload )
  }

  static getGoogleTotpCode() {
    return api.get( "user/totp/qr_code" )
  }

  static enable2Fa( payload ) {
    return api.post( "user/enable_2fa", payload )
  }

  static disable2Fa( payload ) {
    return api.post( "user/disable_2fa", payload )
  }

  static createApiKey( payload ) {
    return api.post( "user/api_keys/create", payload )
  }

  static verifyApiKey( payload ) {
    return api.post( "user/api_keys/verify", payload )
  }

  static deleteApiKey( payload ) {
    return api.delete( "user/api_keys/delete", payload )
  }

  static createWhiteIp( payload ) {
    return api.post( "user/white_ip/create", payload )
  }

  static deleteWhiteIp( payload ) {
    return api.delete( "user/white_ip/delete", payload )
  }

  static sendEmailForResetPassword( payload ) {
    return api.post( "user/reset_password/send_link", payload )
  }

  static async resetPassword( payload ) {
    await api.post( "user/reset_password/set_new_password", payload )
    setTimeout(
      () => {
        router.push( {
          name: "AccountLoginPage"
        } )
      },
      1000
    )
  }

  static async refreshToken() {
    try {
      let response = await api.post( "user/refresh_token", {
        token: localStorage.getItem( "user_token" )
      } )

      helpersUpdateToken( response.data.token, Date.now() )

    } catch ( e ) {
      helpersUpdateToken( "", "" )
      localStorage.setItem( "isAuth", "" )
      router.push( {
        name: "AccountLoginPage"
      } )
    }
  }
}