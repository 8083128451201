import { defineStore } from "pinia"
import { api } from "@/api"

export const useApplication = defineStore( "useApplication", {
  state: () => ( {
    appModeMaintenance: false
  } ),
  actions: {
    apiGetStatistics() {
      api
        .get( "statistics?statistics_type=OPERATIONS_COUNT_PER_MONTH" )
        .catch( e => { this.appModeMaintenance = e.response.status === 503 } )
    }
  }
} )