<template>
  <div class="min-w-[365px] max-w-[1440px] mx-auto px-10 md:px-20">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseContainer",
}
</script>