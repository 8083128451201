import { createApp } from "vue"
import App from "@/App.vue"
import "@/assets/tailwind.css"
import { createPinia } from "pinia"
import i18n from "@/i18n/index.js"
import { SetupCalendar } from "v-calendar"
import "v-calendar/dist/style.css"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import router from "@/router"
import Maska from "maska"
import vClickOutside from "click-outside-vue3"
import FloatingVue from "floating-vue"
import "floating-vue/dist/style.css"
import VueTippy from "vue-tippy"
import "tippy.js/dist/tippy.css"
import VueCountdown from "@chenfengyuan/vue-countdown"

const app = createApp( App )
app.component( VueCountdown.name, VueCountdown )

import "@/socket";

app.use( createPinia() )
  .use( Maska )
  .use( router )
  .use( i18n )
  .use( Toast )
  .use( SetupCalendar )
  .use( vClickOutside )
  .use( FloatingVue )
  .use( VueTippy )
  .mount( "#app" )
