export default {
  EN: {
    deposit: "Deposit",
    currency: "Currency",
    to: "To",
    amount: "Amount",
    network: "Network",
    address: "Address",
    // copyAddressToClipboard: "Copy address to clipboard",
    copyAddressToClipboard: "Copy address",
    fee: "Fee",
    min: "Min",
    max: "Max",
    addressCopied: "Address copied to clipboard",
    depositCurrentlyNotWorking: "The deposit is currently not working",
    cancel: "Cancel",
    qrCode: "QR code",
    confirm: "Confirm",
  },
  RU: {
    deposit: "Пополнить",
    currency: "Валюта",
    to: "Кому",
    amount: "Сумма",
    network: "Сеть",
    address: "Адрес",
    // copyAddressToClipboard: "Скопировать адрес в буфер обмена",
    copyAddressToClipboard: "Скопировать адрес",
    fee: "Комиссия",
    min: "Мин",
    max: "Макс",
    addressCopied: "Адрес скопирован в буфер обмена",
    depositCurrentlyNotWorking: "Пополнение в настоящее время не работает",
    cancel: "Отменить"
  },
  UK: {
    deposit: "Поповнити",
    currency: "Валюта",
    to: "Кому",
    amount: "Сума",
    network: "Мережа",
    address: "Адреса",
    // copyAddressToClipboard: "Копіювати адресу в буфер обміну",
    copyAddressToClipboard: "Копіювати адресу",
    fee: "Комісія",
    min: "Мін",
    max: "Макс",
    addressCopied: "Адреса скопійована в буфер обміну",
    depositCurrentlyNotWorking: "Поповнення в даний час не працює",
    cancel: "Скасувати"
  }
}

