<template>
  <div class="border-t border-gray-300 py-20 md:py-48">
    <BaseContainer>
      <div class="flex flex-col md:flex-row gap-20 md:gap-48 xl:gap-64">
        <div class="flex items-center md:items-end gap-10 md:flex-col">
          <img
            class="h-32 md:h-48 xl:h-[76px] w-auto mb-10"
            src="@/assets/images/company-logo.svg"
            alt="company-logo"
          >
          <div class="text-[#6C7D92] lg:pr-[14%] lg:text-right text-medium whitespace-nowrap">
            © Cryptoexchange.com 2022
          </div>
        </div>
        <ul class="grow grid grid-cols-3 xl:grid-cols-[1fr_2fr_1fr] gap-16 text-[#323232] font-bold">
          <li
            v-for="menuItem, index in menuItems"
            :key="index"
          >
            <a
              v-if="menuItem.link"
              class="text-inherit hover:text-green-600"
              :href="menuItem.link || ''"
            >
              {{ menuItem.label }}
            </a>
            <div
              v-else
            >
              {{ menuItem.label }}
            </div>
            <ul
              v-if="menuItem.children"
              class="mt-8 font-medium"
              :class="{'xl:columns-2': index === 1}"
            >
              <li
                v-for="menuItemChild in menuItem.children"
                :key="menuItemChild.label"
                class="mb-8"
              >
                <a
                  class="text-[#6C7D92] hover:text-green-600"
                  :href="menuItemChild.link"
                >
                  {{ menuItemChild.label }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </BaseContainer>
  </div>
</template>

<script>
import { mapWritableState } from "pinia"
import { useNavigationFooter } from "@/stores/navigationFooter"
import BaseContainer from "@/components/UI/BaseContainer.vue"
export default {
  name: "NavigationFooter",
  components: {
    BaseContainer,
  },
  computed: {
    ...mapWritableState( useNavigationFooter, [ "menuItems", ] ),
  },
};
</script>
