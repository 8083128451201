import { api } from "@/api"

export default class Public {
  static getCurrencies() {
    return api.get( "currency" )
  }

  static getPairs() {
    return api.get( "pair" )
  }

  static getExchangeRate() {
    return api.get( "exchange_rate" )
  }
}