export default {
  EN: {
    fieldRequired: "This field is required",
    maxLimitOrBalance: "The value cannot be more than the balance or the maximum limit",
    maxLimit: "The value cannot be more than the maximum limit",
    maxLimitText: "The maximum number of characters is 255",
    minLimit: "The value cannot be less the minimum limit",
    zeroLimit: "The value cannot be less than 0",
    email: "Must be a valid email",
    next: "Next",
    previous: "Previous",
    currency: "Curreny",
    placeholder: {
      comment: "Your message"
    },
    regPass: "The password must be no less than 8 characters long, contain a capital letter, a special symbol and a number"
  },
  RU: {
    fieldRequired: "Это поле обязательно к заполнению",
    maxLimitOrBalance: "Значение не может быть больше баланса или максимального лимита",
    maxLimit: "Значение не может быть больше максимального лимита",
    maxLimitText: "Максимальное количество символов 255",
    minLimit: "Значение не может быть меньше минимального лимита",
    zeroLimit: "Значение не может быть меньше 0",
    email: "Должен быть действительный адрес электронной почты",
    next: "Далее",
    previous: "Назад",
    currency: "Валюта",
    placeholder: {
      comment: "Ваш комментарий"
    },
    regPass: "Пароль должен содержать не менее 8 символов, заглавную букву, специальный символ и цифру"
  },
  UK: {
    fieldRequired: "Це поле є обов'язковим",
    maxLimitOrBalance: "Значення не може бути більше за баланс або за максимальний ліміт",
    maxLimit: "Значення не може бути більше за максимальний ліміт",
    maxLimitText: "Максимальна кількість символів 255",
    minLimit: "Значення не може бути менше за мінімальний ліміт",
    zeroLimit: "Значення не може бути менше за 0",
    email: "Має бути дійсною електронною поштою",
    next: "Далі",
    previous: "Назад",
    currency: "Валюта",
    placeholder: {
      comment: "Ваш коментар"
    },
    regPass: "Пароль повинен містити не менше 8 символів, велику літеру, спеціальний символ та цифру"
  }
}