export default {
  EN: {
    pageNotFound: "Page not found",
    goHome: "Go to home",
  },
  RU: {
    pageNotFound: "Страница не найдена",
    goHome: "Перейти на главную",
  },
  UK: {
    pageNotFound: "Сторінка не знайдена",
    goHome: "Перейти на головну",
  },
}