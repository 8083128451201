export default {
  EN: {
    feesAndLimits: "Fees and limits",
    deposit: "Deposit",
    withdrawal: "Withdrawal",
    fees: "Fees",
    min: "Min",
    max: "Max",
    fiat: "Fiat",
    crypto: "Crypto",
  },
  RU: {
    feesAndLimits: "Комиссии и лимиты",
    deposit: "Пополнить",
    withdrawal: "Отправить",
    fees: "Комиссия",
    min: "Мин",
    max: "Макс",
    fiat: "Фиатная валюта",
    crypto: "Криптовалюта",
  },
  UK: {
    feesAndLimits: "Комісії та ліміти",
    deposit: "Поповнити",
    withdrawal: "Надіслати",
    fees: "Комісія",
    min: "Мін",
    max: "Макс",
    fiat: "Фіатна валюта",
    crypto: "Криптовалюта",
  },
}