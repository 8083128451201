export default { 
  EN: {
    saveNewPassword: "Save new password",
    passwordsMustMatch: "Passwords must match",
    save: "Save",
    cancel: "Cancel",
    changePassword: "Change password",
  },
  RU: {
    saveNewPassword: "Save new password",
    passwordsMustMatch: "Passwords must match",
    save: "Save",
    cancel: "Cancel",
  },
  UK: {
    saveNewPassword: "Save new password",
    passwordsMustMatch: "Passwords must match",
    save: "Save",
    cancel: "Cancel",
  },
}

