export default {
  EN: {
    title: "History",
    dontData: "You have no orders history, or there are no records to match your search filters",
    type: "Type",
    date: "Date",
    currency: "Currency",
    status: "Status",
    internalId: "Internal ID",
    externalId: "External ID",
    buy: "Buy",
    sell: "Sell",
    rate: "Rate",
    fee: "Fee",
    transactionHash: "Transaction hash",
    linkHashTransaction: "Link hash transaction",
    userName: "User name",
    accountNumber: "Account number",
    comment: "Comment",
    currencyToPay: "Currency to pay",
    amountToPay: "Amount to pay",
    sourceAccount: "Source account",
    customerEmail: "Customer email",
    destinationAccount: "Destination account",
    statusAll: "All",
    statusNew: "New",
    statusCompleted: "Completed",
    statusError: "Error",
    statusClosed: "Closed",
    statusExpired: "Expired",
    statusCancelled: "Cancelled",
    statusCancelling: "Cancelling",
    statusWaiting: "Waiting",
    statusWaitingForOperatorConfirmation: "Waiting for operator confirmation",
    statusConfirmedByOperator: "Confirmed by operator",
    statusCancelledByOperator: "Cancelled by operator",
    statusWaitingForConfirmation: "Waiting for confirmation",
    statusWaitingForPrice: "Waiting for price",
    statusPaymentInProgress: "Payment in progress",
    statusBlocked: "Blocked",
    statusCancel: "Cancelled",
    statusConfirm: "Confirmation",
    statusInProgress: "In progress",
    priceWaiting: "Waiting for price",
    statusWaitingForCurrencyConvert: "Waiting for currency convert",
    statusOpenPosition: "Open position",
    typeAll: "All",
    typeExchange: "Exchange",
    typeDeposit: "Deposit",
    typeWithdraw: "Withdraw",
    typeInternal: "Internal",
    typeInvoice: "Invoice",
    orderTypes: {
      withdraw: "Withdrawal",
      withdrawCourier: "Courier withdrawal",
      withdrawCashbox: "Cashbox withdrawal",
      withdrawGateway: "Card withdrawal",
      deposit: "Deposit",
      depositCourier: "Courier deposit",
      depositCashbox: "Cashbox deposit",
      depositGateway: "Card deposit",
      transfer: "Internal transfer",
      invoice: "Invoice payment",
      market: "Market Exchange",
      limit: "Limit Exchange"
    },
    btnFilter: "Filters",
    btnFilterCancel: "Cancel",
    btnFilterConfirm: "Apply",
    sender: "Sender",
    transferFundsToTheUser: "Transfer funds to the user",
    applicationNumber: "Application number",
    btnReceipt: "Receipt",
    btnRepeat: "Repeat",
    address: "Wallet address",
    transactionId: "ID",
    cardNumber: "Card number",
    accountBalance: "Account balance",
    riskTrade: "Risk per trade",
    riskTradeReward: "Risk Reward per trade",
    stopPriceInUsd: "Stop price",
    takeProfitPriceInUsd: "Take profit price",
    stopLossAmount: "Stop loss amount",
    takeProfitAmount: "Take profit amount",
  },

  RU: {
    title: "История",
    dontData: "У Вас нет истории операций или нет записей, соответствующих вашим поисковым фильтрам",
    type: "Тип",
    date: "Дата",
    currency: "Валюта",
    status: "Статус",
    internalId: "Внутренний ID",
    externalId: "Внешний ID",
    buy: "Купить",
    sell: "Продавать",
    rate: "Курс",
    fee: "Комиссия",
    transactionHash: "Хеш транзакции",
    linkHashTransaction: "Ссылка хеш транзакции",
    userName: "Имя пользователя",
    accountNumber: "Номер счета",
    comment: "Комментарий",
    currencyToPay: "Валюта для оплаты",
    amountToPay: "Сумма к оплате",
    sourceAccount: "Исходная учетная запись",
    customerEmail: "Электронная почта",
    destinationAccount: "Целевая учетная запись",
    statusAll: "Все",
    statusNew: "Новый",
    statusError: "Ошибка",
    statusClosed: "Закрыт",
    statusExpired: "Истек",
    statusCancelled: "Отменено",
    statusCancelling: "Отменяется",
    statusWaiting: "Ожидание",
    statusWaitingForOperatorConfirmation: "Ожидание подтверждения оператора",
    statusConfirmedByOperator: "Подтверждено оператором",
    statusCancelledByOperator: "Отменено оператором",
    statusWaitingForConfirmation: "Ожидание подтверждения",
    statusWaitingForPrice: "Ожидание цены",
    statusPaymentInProgress: "Выполняется платеж",
    statusBlocked: "Заблокировано",
    statusCancel: "Отмена",
    statusConfirm: "Подтверждение",
    statusInProgress: "В обработке",
    priceWaiting: "Ожидание цены",
    statusWaitingForCurrencyConvert: "Ожидание конвертации валюты",
    statusOpenPosition: "Открытая позиция",
    typeAll: "Все",
    typeExchange: "Обмен",
    typeDeposit: "Пополнение",
    typeWithdraw: "Вывод",
    typeInternal: "Внутренний перевод",
    typeInvoice: "Оплата счета",
    orderTypes: {
      withdraw: "Вывод",
      withdrawCourier: "Вывод курьером",
      withdrawCashbox: "Вывод в кассе",
      withdrawGateway: "Вывод на карту",
      deposit: "Пополнение",
      depositCourier: "Пополнение курьером",
      depositCashbox: "Пополнение на кассе",
      depositGateway: "Пополнение картой",
      transfer: "Перевод пользователю",
      invoice: "Оплата счета",
      market: "Обмен (маркет)",
      limit: "Обмен (лимит)"
    },
    btnFilter: "Фильтр",
    btnFilterCancel: "Отменить",
    btnFilterConfirm: "Применить",
    sender: "Отправитель",
    transferFundsToTheUser: "Перевод средств пользователю",
    applicationNumber: "Номер заявки",
    btnReceipt: "Квитанция",
    btnRepeat: "Повторить",
    address: "Адрес кошелька",
    transactionId: "ID",
    cardNumber: "Номер карты",
    accountBalance: "Баланс",
    riskTrade: "Риск на 1 сделку",
    riskTradeReward: "Риск Reward на сделку",
    stopPriceInUsd: "Stop price",
    takeProfitPriceInUsd: "Take profit price",
    stopLossAmount: "Stop loss amount",
    takeProfitAmount: "Take profit amount",
  },

  UK: {
    title: "Історія",
    dontData: "У Вас немає історії операцій або немає записів, що відповідають вашим пошуковим фільтрам",
    type: "Тип",
    date: "Дата",
    currency: "Валюта",
    status: "Статус",
    internalId: "Внутрішній ID",
    externalId: "Зовнішній ID",
    buy: "Придбаю",
    sell: "Продаю",
    rate: "Курс",
    fee: "Комісія",
    transactionHash: "Хеш транзакції",
    linkHashTransaction: "Хеш-транзакція посилань",
    userName: "Ім'я користувача",
    accountNumber: "Номер рахунку",
    comment: "Коментар",
    currencyToPay: "Валюта для оплати",
    amountToPay: "Сума для оплати",
    sourceAccount: "Вихідний обліковий запис",
    customerEmail: "Електронна адреса клієнта",
    destinationAccount: "Цільовий рахунок",
    statusAll: "Всі",
    statusNew: "Новий",
    statusError: "Помилка",
    statusClosed: "Закрито",
    statusExpired: "Термін дії завершився",
    statusCancelled: "Скасовано",
    statusCancelling: "Скасування",
    statusWaiting: "Очікування",
    statusWaitingForOperatorConfirmation: "Очікування підтвердження оператора",
    statusConfirmedByOperator: "Підтверджено оператором",
    statusCancelledByOperator: "Скасовано оператором",
    statusWaitingForConfirmation: "Чекаємо підтвердження",
    statusWaitingForPrice: "Очікування ціни",
    statusPaymentInProgress: "Виконується оплата",
    statusBlocked: "Заблоковано",
    statusCancel: "Відміна",
    statusConfirm: "Підтвердженя",
    statusInProgress: "В обробці",
    priceWaiting: "Очікування ціни",
    statusWaitingForCurrencyConvert: "Очікування конвертації валюти",
    statusOpenPosition: "Відкрита позиція",
    typeAll: "Всі",
    typeExchange: "Обмін",
    typeDeposit: "Поповнення",
    typeWithdraw: "Виведення",
    typeInternal: "Внутрішній переказ",
    typeInvoice: "Оплата рахунку",
    orderTypes: {
      withdraw: "Виведення",
      withdrawCourier: "Виведення курьером",
      withdrawCashbox: "Виведення через касу",
      withdrawGateway: "Виведення на карту",
      deposit: "Поповнення",
      depositCourier: "Поповнення курьером",
      depositCashbox: "Поповнення через касу",
      depositGateway: "Поповнення картою",
      transfer: "Переказ користувачу",
      invoice: "Оплата рахунку",
      market: "Обмін (маркет)",
      limit: "Обмін (лiмiт)"
    },
    btnFilter: "Фільтр",
    btnFilterCancel: "Скасувати",
    btnFilterConfirm: "Застосувати",
    sender: "Відправник",
    transferFundsToTheUser: "Переказ коштів користувачеві",
    applicationNumber: "Номер заявки",
    btnReceipt: "Квитанція",
    btnRepeat: "Повторити",
    address: "Адреса гаманця",
    transactionId: "ID",
    cardNumber: "Номер карти",
    accountBalance: "Баланс",
    riskTrade: "Ризик на 1 угоду",
    riskTradeReward: "Ризик Reward на угоду",
    stopPriceInUsd: "Stop price",
    takeProfitPriceInUsd: "Take profit price",
    stopLossAmount: "Stop loss amount",
    takeProfitAmount: "Take profit amount",
  }
}