export default {
  EN: {
    baseBlockHead: "Invoice",
    email: "Whom",
    sum: "Sum",
    commission: "Commission",
    min: "Min",
    max: "Max",
    typeOfPayment: "Type of payment",
    comment: "Comment",
    showResult: "Display invoice after creation",
    btnText: "Create",
  },
  RU: {
    baseBlockHead: "Выставить счет",
    email: "Кому",
    sum: "Сумма",
    commission: "Комиссия",
    min: "Мин",
    max: "Макс",
    typeOfPayment: "Вид оплаты",
    comment: "Комментарий",
    showResult: "Отобразить инвойс после создания",
    btnText: "Создать",
  },
  UK: {
    baseBlockHead: "Виставити рахунок",
    email: "Кому",
    sum: "Сума",
    commission: "Комісія",
    min: "Мін",
    max: "Макс",
    typeOfPayment: "Вид оплати",
    comment: "Коментар",
    showResult: "Відобразити інвойс після створення",
    btnText: "Створити",
  },
}



















