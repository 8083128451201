export default {
  EN: {
    titleBlock: "Exchange",
    titleOtherCurrencies: "Exchange rate of other currencies",
    operationMarket: "Market",
    operationLimit: "Limit",
    btnText: "Exchange",
    sell: "Sell",
    buy: "Buy",
    exchangeRate: "Exchange rate",
    balance: "Balance",
    min: "Min",
    max: "Max",
    fee: "Fee",
    availableFunds: "Available funds",
  },
  RU: {
    titleBlock: "Обмен",
    titleOtherCurrencies: "Курс других валют",
    operationMarket: "Маркет",
    operationLimit: "Лимит",
    btnText: "Обмен",
    sell: "Продаю",
    buy: "Покупаю",
    exchangeRate: "Курс",
    balance: "Баланс",
    min: "Мин",
    max: "Макс",
    fee: "Комиссия",
    availableFunds: "Доступные средства",
  },
  UK: {
    titleBlock: "Обмін",
    titleOtherCurrencies: "Курс інших валют",
    operationMarket: "Маркет",
    operationLimit: "Ліміт",
    btnText: "Обмін",
    sell: "Продаю",
    buy: "Придбаю",
    exchangeRate: "Курс",
    balance: "Баланс",
    min: "Мін",
    max: "Макс",
    fee: "Комісія",
    availableFunds: "Доступні кошти",
  }
}

