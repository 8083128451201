<template>
  <div class="font-roboto text-black flex flex-col min-h-screen">
    <TechWorks v-if="appModeMaintenance" />
    <div v-else>
      <NavigationHeader />
      <div class="py-20 md:py-32 grow">
        <BaseContainer>
          <router-view />
        </BaseContainer>
      </div>
      <NavigationFooter />
    </div>
  </div>
</template>

<script>
import { setLocale } from "yup"
import { mapActions, mapState } from "pinia"
import { useUser } from "@/stores/user"
import moment from "moment"
import User from "@/api/UserData"
import NavigationHeader from "@/components/NavigationHeader"
import NavigationFooter from "@/components/NavigationFooter"
import TechWorks from "@/components/TechWorks"
import { useApplication } from "@/stores/application"
import BaseContainer from "@/components/UI/BaseContainer"

export default {
  name: "App",

  components: {
    TechWorks,
    NavigationHeader,
    NavigationFooter,
    BaseContainer,
  },

  computed: {
    ...mapState( useApplication, [ "appModeMaintenance" ] )
  },

  created() {
    this.apiGetStatistics()

    this.appInitialize()
    setInterval(
      () => {
        if ( localStorage.getItem( "user_token_time" ) ) {
          const oldTime = localStorage.getItem( "user_token_time" ),
            newTime = Date.now(),
            time = Number( newTime ) - Number( oldTime )

          if ( time >= 300000 ) {
            User.refreshToken()
          }
        }
      },
      60000
    )

    // console.log(  );
    // this.$validator.pause()
  },

  methods: {
    ...mapActions( useUser, [
      "apiGetCurrencies",
      "apiGetPairs",
      "apiExchangeRates",
    ] ),
    ...mapActions( useApplication, [ "apiGetStatistics" ] ),

    fixSafariDefocusToInput() {
      document.body.firstElementChild.tabIndex = 1
    },
    customizeInputValidateMessage() {
      setLocale( {
        mixed: {
          // TODO: need fix not type
          notType: this.$t( "inputs.fieldRequired" ),
          required: this.$t( "inputs.fieldRequired" )
        },
        number: {
          integer: "This is not an integer"
        },
        string: {
          required: this.$t( "inputs.fieldRequired" ),
          email: this.$t( "inputs.email" )
        }
      } )
    },
    appInitialize() {
      this.apiGetCurrencies()
      this.apiGetPairs()
      this.customizeInputValidateMessage()
      this.fixSafariDefocusToInput()
      this.apiExchangeRates()
      moment.locale( "en" )
    }
  }
}
</script>