export default {
  EN: {
    title: "Wallet",
    total: "total",
    reserved: "reserved",
    balance: "Total balance",
    balanceType: "Balance",
    tradingType: "Trading",
    btnWithdraw: "Withdraw",
    btnDeposit: "Deposit",
    typeViewLabel: "View",
    typeViewSimple: "Simple",
    typeViewCompact: "Compact",
    listFiltersCrypto: "Only crypto",
    listFiltersFiat: "Only fiat",
    listFiltersAllNotZero: "Hide with zero balance",
    titleFilterCurrency: "Balance currency",
    address: "Address",
    transactions: "Transactions",
  },
  RU: {
    title: "Кошелек",
    total: "всего",
    reserved: "в заявках",
    balance: "Баланс",
    balanceType: "Баланс",
    tradingType: "Трейдинг",
    btnWithdraw: "отправить",
    btnDeposit: "пополнить",
    typeViewLabel: "Вид",
    typeViewSimple: "Обычный",
    typeViewCompact: "Компактный",
    listFiltersCrypto: "Только крипто",
    listFiltersFiat: "Только фиатные",
    listFiltersAllNotZero: "Скрыть с нулевым балансом",
    titleFilterCurrency: "Валюта баланса",
    address: "Адрес",
    transactions: "Транзакции",
  },
  UK: {
    title: "Гаманець",
    total: "усього",
    reserved: "в заявках",
    balance: "Баланс",
    balanceType: "Баланс",
    tradingType: "Трейдинг",
    btnWithdraw: "купити",
    btnDeposit: "надіслати",
    typeViewLabel: "Вигляд",
    typeViewSimple: "Звичайний",
    typeViewCompact: "Компактний",
    listFiltersCrypto: "Тільки крипто",
    listFiltersFiat: "Тільки фіатні",
    listFiltersAllNotZero: "Приховати з нульовим балансом",
    titleFilterCurrency: "Валюта балансу",
    address: "Адреса",
    transactions: "Транзакції",
  }
}