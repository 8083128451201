<template>
  <section
    class="font-roboto fixed w-full h-full flex flex-col justify-center items-center bg-blue-900 text-center text-white text-[18px] md:text-[30px] px-10 md:px-32 bg-black bg-[url(@/assets/images/auth_bg.png)] bg-no-repeat bg-cover bg-center"
  >
    <img
      class="w-[300px] h-auto mb-32"
      src="@/assets/icons/company-logo-white.svg"
      alt="logo"
    >
    <h1 class="text-green-600 text-[26px] md:text-[40px] mb-20">
      Dear users!
    </h1>
    <p class="mb-20">
      <span class="text-green-600 font-medium">CRYPTO</span>
      informs you that we are undergoing maintenance at the moment.
    </p>
    <p>Thanks for working with us!</p>
  </section>
</template>

<script>
export default {
  name: "TechWorks"
}
</script>