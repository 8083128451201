export default { 
  EN: {
    internalTransfer: "Internal Transfer",
    transfer: "Transfer",
    to: "To",
    email: "Email",
    amount: "Amount",
    fee: "Fee",
    paymentType: "Payment type",
    comment: "Comment",
    yourComment: "Your comment",
    min: "Min",
    max: "Max",
    availableFunds: "Available funds",
    withdrawalAll: "Withdrawal all",
    cancel: "Cancel",
  },
  RU: {
    internalTransfer: "Внутренний перевод",
    transfer: "Перевести",
    to: "Кому",
    email: "Email",
    amount: "Сумма",
    fee: "Комиссия",
    paymentType: "Вид оплаты",
    comment: "Комментарий",
    yourComment: "Ваш комментарий",
    min: "Мин",
    max: "Макс",
    availableFunds: "Доступные средства",
    withdrawalAll: "Отправить все",
    cancel: "Отменить",
  },
  UK: {
    internalTransfer: "Внутрішній переказ",
    transfer: "Створити",
    to: "Кому",
    email: "Пошта",
    amount: "Сума",
    fee: "Комісія",
    paymentType: "Вид оплати",
    comment: "Коментар",
    yourComment: "Ваш коментар",
    min: "Мін",
    max: "Макс",
    availableFunds: "Доступні кошти",
    withdrawalAll: "Надіслати все",
    cancel: "Скасувати",
  },
}

