export default {
  EN: {
    email: "Email",
    name: "Name",
    password: "Password",
    passwordConfirm: "Password confirm",
    btnRegistration: "Registration",
    login: "Login",
  },
  RU: {
    email: "Почта",
    name: "Имя",
    password: "Пароль",
    passwordConfirm: "Подтверждение пароля",
    btnRegistration: "Зарегистрироваться",
  },
  UK: {
    email: "Пошта",
    name: "Ім'я",
    password: "Пароль",
    passwordConfirm: "Підтвердження пароля",
    btnRegistration: "Зареєструватись",
  }
}