export default {
  // This file has translations of obviously global phrases, such as validation errors
  EN: {
    xlsx: {
      number: "№",
      InternalID: "Internal ID",
      Data: "Data",
      TransactionType: "Transaction Type",
      Amount: "Amount",
      Currency: "Currency",
      Fee: "Fee",
      FeeCurrency: "Fee Currency",
      Comment: "Comment",
      Status: "Status",
      TransactionHash: "Transaction Hash",
      externalId: "external id",
      Buy: "Buy",
      Sell: "Sell",
      Rate: "Rate",
      all: "ALL",
      withdrawal: "WITHDRAWAL",
      deposit: "DEPOSIT",
      exchange: "EXCHANGE",
      internalMovement: "INTERNAL_MOVEMENT",
      invoice: "INVOICE"
    },
    backStepPage: "back",
    errorStatusRequest: "Error request",
    dontHaveCurrencyInOtherRates: "Unfortunately we do not have an exchange rate with the selected currency",
    copiedToClipboard: "Copied to clipboard",
  },
  RU: {
    xlsx: {
      number: "№",
      InternalID: "Internal ID",
      Data: "Дата",
      TransactionType: "Тип",
      Amount: "Сумма",
      Currency: "Валюта",
      Fee: "Комиссия",
      FeeCurrency: "Валюта комиссии",
      Comment: "Комментарий",
      Status: "Статус",
      TransactionHash: "Хэш операции",
      externalId: "external id",
      Buy: "Куплено",
      Sell: "Продано",
      Rate: "Курс",
      all: "ВСЕ",
      withdrawal: "Вывод",
      deposit: "Пополнение",
      exchange: "Обмен",
      internalMovement: "Внутренний перевод",
      invoice: "Оплата счета"
    },
    backStepPage: "назад",
    errorStatusRequest: "Произошла ошибка запроса",
    dontHaveCurrencyInOtherRates: "К сожалению нет курса с выбранной валютой",
    copiedToClipboard: "Скопировано в буфер обмена",
  },
  UK: {
    xlsx: {
      number: "№",
      InternalID: "Internal ID",
      Data: "Дата",
      TransactionType: "Тип",
      Amount: "Сума",
      Currency: "Валюта",
      Fee: "Комісія",
      FeeCurrency: "Валюта комісії",
      Comment: "Коментар",
      Status: "Статус",
      TransactionHash: "Хеш операції",
      externalId: "external id",
      Buy: "Придбано",
      Sell: "Продано",
      Rate: "Курс",
      all: "ВСІ",
      withdrawal: "Виведення",
      deposit: "Поповнення",
      exchange: "Обмін",
      internalMovement: "Внутрішній переказ",
      invoice: "Оплата рахунку"
    },
    backStepPage: "назад",
    errorStatusRequest: "Сталась помилка запиту",
    dontHaveCurrencyInOtherRates: "На жаль не маємо курсу з обраною валютою",
    copiedToClipboard: "Скопійовано в буфер обміну",
  },
}

