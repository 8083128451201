import axios from "axios"
import { useUser } from "@/stores/user"
import i18n from "@/i18n"
import { helpersNotification, helpersUpdateToken } from "@/utils/helpers.js"
import router from "@/router"

const options = {
  baseURL: process.env.VUE_APP_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "en-US"
  }
}

let api = axios.create( options )

api.interceptors.request.use( config => {
  const userStore = useUser()
  if ( !userStore.responseInfo.some( item => item.url === config.url ) ) {
    userStore.responseInfo.push( {
      url: config.url,
      status: "loading"
    } )
  } else {
    userStore.responseInfo.forEach( item => {
      if ( item.url === config.url ) {
        item.status = "loading"
      }
    } )
  }

  const token = localStorage.getItem( "user_token" )
  config.headers.Authorization = token
    ? `Bearer ${ token }`
    : ""

  return config
} )

api.interceptors.response.use(
  response => {
    const userStore = useUser()
    const { config: { url } } = response
    userStore.responseInfo.forEach( item => {
      if ( item.url === url ) {
        item.status = "success"
      }
    } )
    const urlsNeedToUpdateAccountInfo = [
      "user/api_keys/verify",
      "user/api_keys/delete",
      "user/white_ip/create",
      "user/white_ip/delete",
    ]
    if ( urlsNeedToUpdateAccountInfo.some( Url => Url === url ) ) {
      userStore.apiAccountInfo()
    }

    const successNotifications = [
      "user/create",
      "user/change_password",
      "user/enable_2fa",
      "user/disable_2fa",
      "user/api_keys/create",
      "user/api_keys/verify",
      "user/api_keys/delete",
      "user/white_ip/create",
      "user/white_ip/delete",
      "user/reset_password/send_link",
      "user/reset_password/set_new_password",

      "withdrawal",
      "withdrawal/cancel",
      "withdrawal/repeat",
      "withdrawal/request/create",
      "internal_movement",
      "internal_movement/repeat",
      "invoice/",
      "invoice/pay/public",
      "invoice/pay",
      "exchange",
      "exchange/cancel",
      "exchange/repeat",
    ]
    if ( successNotifications.some( Url => Url === url ) ) {
      helpersNotification( {
        type: "success",
        message: i18n.global.tc( `apiNotifications.${url}` )
      } )
    }

    return response
  },
  async err => {
    const userStore = useUser()
    userStore.responseInfo.forEach( item => {
      if ( item.url === err.config.url ) {
        item.status = "error"
      }
    } )

    if( err.response.status === 403 || err.response.status === 401 ) {
      helpersUpdateToken( "", "" )
      localStorage.setItem( "isAuth", "" )
      router.push( {
        name: "AccountLoginPage"
      } )
    }

    if ( err.response?.data?.detail ) {
      helpersNotification( {
        type: "error",
        message: err.response?.data?.detail
      } )
    } else {
      // TODO: At this place you can work out arbitrary errors of answers
      const errorNotifications = [ "user/refresh_token" ]
      if ( errorNotifications.some( Url => Url === url ) ) {
        helpersNotification( {
          type: "error",
          message: i18n.global.tc( `apiNotifications.error.${url}` )
        } )
      }
    }

    return Promise.reject( err )
  }
)

export { api }