export default { 
  EN: {
    withdrawal: "Withdrawal",
    currency: "Currency",
    to: "Enter the card number",
    amount: "Amount",
    network: "Network",
    address: "Address",
    comment: "Comment",
    fee: "Fee",
    availableFunds: "Available funds",
    min: "Min",
    max: "Max",
    withdrawalCurrentlyNotWorking: "The withdrawal is currently not working",
    withdrawalAll: "Withdrawal all",
    cancel: "Cancel",
  },
  RU: {
    withdrawal: "Отправить",
    currency: "Валюта",
    to: "Введите номер карты",
    amount: "Сумма",
    network: "Сеть",
    address: "Адрес",
    comment: "Комментарий",
    fee: "Комиссия",
    availableFunds: "Доступные средства",
    min: "Мин",
    max: "Макс",
    withdrawalCurrentlyNotWorking: "Вывод в настоящее время не работает",
    withdrawalAll: "Отправить все",
    cancel: "Отменить",
  },
  UK: {
    withdrawal: "Надіслати",
    currency: "Валюта",
    to: "Введіть номер карти",
    amount: "Сума",
    network: "Мережа",
    address: "Адреса",
    comment: "Коментар",
    fee: "Комісія",
    availableFunds: "Доступні кошти",
    min: "Мін",
    max: "Макс",
    withdrawalCurrentlyNotWorking: "Вивід на даний час не працює",
    withdrawalAll: "Надіслати все",
    cancel: "Скасувати",
  },
}

