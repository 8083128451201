import { defineStore } from "pinia"
import { helpersRoundingCurrency } from "@/utils/helpers"
import User from "@/api/UserData"
import Public from "@/api/PublicData"
import i18n from "@/i18n"

export const useUser = defineStore( "user", {
  state: () => ( {
    responseInfo: [],
    selectedCurrentTotalBalance: "BTC",
    listCurrencyTotalBalance: [
      "BTC",
      "UAH",
      "USDT",
      "USD"
    ],
    accountInfo: {},
    userBalance: {},
    userTradingBalance: {},
    userWallets: {},
    currencies: [],
    pairs: [],
    googleTotpCode: {},
    buttons: [
      "Баланс",
      "Трейдинг"
    ],
    typeBalance: i18n.global.tc( "walletBalance.balanceType" ),
    exchangeRates: [],
  } ),

  getters: {

    getCurrenciesListToHide: () => [
      "KZT",
      "CAD",
      "UZS",
      "RUB",
      "PLN",
      "NZD",
      "NOK",
      "UACB_NOV22",
      "UACB-NOV22-BEP20",
      "UACB-NOV22-TRC20",
      "UCB",
      "USDTOMNI",
    ],

    getExchangeOrderProcessingRules() {
      return this.accountInfo?.exchange_order_processing_rules || {}
    },
    
    getUserExchangeLimits: state => state.accountInfo?.exchange_order_limits,

    getWithdrawalOrderLimits: state => state.accountInfo?.withdrawal_order_limits,

    getDepositOrderLimits: state => state.accountInfo?.deposit_order_limits,

    getInternalMovementLimits: state => state.accountInfo?.internal_movement_limits,

    getUserBalance: state => state.userBalance,

    getTelegramAccount: state => state.accountInfo?.telegram_account,

    getUserTradingBalanceFormatting() {
      const formattingObject = Object.entries( this.userTradingBalance )
        .map( ( [
          key,
          val 
        ] ) => {
          return [
            key,
            {
              [ key ]: {
                ...val 
              },
              currency: key 
            } 
          ]
        } )
      
      return Object.fromEntries( formattingObject )
    },
    
    getCurrentUserBalance() {
      return ( currency ) => {
        const totalCurrBalance = this.getUserBalance?.[ currency ]?.[ currency ]?.total
        const reservedCurrBalance = this.getUserBalance?.[ currency ]?.[ currency ]?.reserved

        return helpersRoundingCurrency( {
          currencyAmount: totalCurrBalance - reservedCurrBalance,
          currency: currency
        } )
      }
    },

    getCurrentTradingBalance() {
      return ( currency ) => {
        return this.userTradingBalance?.[ currency ]
      }
    },

    getUserWallets: state => state.userWallets,

    getDepositOrderProcessingRuless: state => state.accountInfo?.deposit_order_processing_rules ?? {},

    getInvoiceOrderProcessingRules: state => state.accountInfo?.invoice_order_processing_rules || {},
    
    getDepositOrderFees: state => state.accountInfo?.deposit_order_fees,

    getWithdrawalOrderFees: state => state.accountInfo?.withdrawal_order_fees,

    getInternalMovementFees: state => state.accountInfo?.internal_movement_fees || {},
    
    getWithdrawalOrderProcessingRuless: state => state.accountInfo?.withdrawal_order_processing_rules ?? {},

    getInternalMovementProcessingRuless: state => state.accountInfo?.internal_movement_processing_rules ?? {},
    
    getUserExchangeOrderFees: state => state.accountInfo?.exchange_order_fees,

    getEmail: state => state.accountInfo?.email,

    getApiKeysList: state => state.accountInfo?.api_keys_list,

    getWhiteIpList: state => state.accountInfo?.white_ip_list,

    getAccountType: state => state.accountInfo?.account_type,

    getAccountInfoProperty: state => property => state.accountInfo?.[ property ],

    getSumCurrentTotalBalance() {
      const currencyAmount = Object.values( this.getUserBalance )
        .reduce( ( acc, item ) => {
          if ( typeof item[ this.selectedCurrentTotalBalance ] === "object" && item[ this.selectedCurrentTotalBalance ] ) {
            acc += item[ this.selectedCurrentTotalBalance ]?.total
          }

          return acc
        }, 0 )

      const value = helpersRoundingCurrency( {
        currencyAmount,
        currency: this.selectedCurrentTotalBalance 
      } )

      return value
    },

    getAccountSettings: state => state.accountInfo?.account_settings || {},
    
    getPairs: state => state.pairs,
    
    getInvoiceOrderLimits() {
      return this.accountInfo?.invoice_order_limits
    },

    getSubAccountsId() {
      return this.accountInfo?.sub_accounts?.filter( item => item?.sub_account_type === "TRADING" )?.[ 0 ]?.id
    },

    getGoogleTotpCode: state => state.googleTotpCode || {},

    getIs2faEnabled: state => state.accountInfo?.is_2fa_enabled,

    getUserBalanceWithTypeBalance() {
      return this.typeBalance ===  i18n.global.tc( "walletBalance.balanceType" )
        ? this.getUserBalance
        : [ {
          USDT: this.getCurrentTradingBalance( "USDT" )
            ? this.getCurrentTradingBalance( "USDT" )
            : 0,
          currency: "USDT"
        } ]
    },
  },

  actions: {
    apiAccountInfo() {
      User.getAccountInfo()
        .then( res => this.accountInfo = res.data )
    },

    apiUserBalance() {
      User.getBalance()
        .then( ( { data: {
          balance, trading_balance, wallets 
        } } = response ) => {
          this.userBalance = {}
          Object.keys( balance )
            .forEach( currency => {
              if ( !this.getCurrenciesListToHide.includes( currency ) ) this.userBalance[currency] = balance[currency];
            } );

          this.userTradingBalance = trading_balance
          this.userWallets = wallets
        } )
    },

    apiExchangeRates() {
      Public.getExchangeRate()
        .then( res => { this.exchangeRates = res.data.rates } )
    },

    apiGetCurrencies() {
      Public.getCurrencies()
        .then( res => { this.currencies = res.data.currencies.filter( currency => !this.getCurrenciesListToHide.includes( currency ) ) } )
    },

    apiGetPairs() {
      Public.getPairs()
        .then( res => { this.pairs = res.data.pairs } )
    },

    apiGetGoogleTotpCode() {
      User.getGoogleTotpCode()
        .then( res => { this.googleTotpCode = res.data } )
    },

    apiLogoutTelegram() {
      const data = {
        "username": this.getTelegramAccount
      }

      User.logoutTelegram( data )
        .then( () => { this.apiAccountInfo() } )
    }
  }
} )
