import { createI18n } from "vue-i18n"
import global from "@/i18n/globalTranslates.js"
import exchange from "@/i18n/exchange.js"
import walletBalance from "@/i18n/walletBalance.js"
import internalMovement from "@/i18n/internalMovement.js"
import depositCurrency from "@/i18n/depositCurrency.js"
import withdrawalCurrency from "@/i18n/withdrawalCurrency.js"
import feesAndLimits from "@/i18n/feesAndLimits.js"
import inputs from "@/i18n/inputs.js"
import history from "@/i18n/history.js"
import pageNotFound from "@/i18n/pageNotFound.js"
import invoiceOperation from "@/i18n/invoiceOperation"
import accountSettingsUser from "@/i18n/accountSettingsUser"
import apiNotifications from "@/i18n/apiNotifications.js"
import account from "@/i18n/account"

const messages = ( ()=>{
  let results = {}
  const langs = [
    "en",
    "ru",
    "uk" 
  ]
  langs.forEach( lang => {
    results[lang] = {}
    const modules = {
      global,
      exchange,
      walletBalance,
      internalMovement,
      depositCurrency,
      withdrawalCurrency,
      feesAndLimits,
      inputs,
      history,
      pageNotFound,
      invoiceOperation,
      accountSettingsUser,
      account,
      apiNotifications,
    }
    Object.keys( modules )
      .forEach( moduleName => {
        results[lang][moduleName] = modules[moduleName]?.[lang.toUpperCase()] || {}
      } ) 
  } )
  return results
} )()

const i18n = createI18n( {
  globalInjection: true,
  locale: "en",
  fallbackLocale: "en",
  messages
} )

export default i18n