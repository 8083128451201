import { createWebHistory, createRouter } from "vue-router"

const UI = () => import( "@/components/UI/UI" )

const NotFoundPage = () => import( "@/pages/NotFoundPage.vue" )
const SettingsUserPage = () => import( "@/pages/SettingsUserPage.vue" )
const SettingsApiPage = () => import( "@/pages/SettingsApiPage.vue" )
const SettingsSecurityPage = () => import( "@/pages/SettingsSecurityPage.vue" )
const InvoiceOrderPage = () => import( "@/pages/InvoiceOrderPage" )
const InvoicePage = () => import( "@/pages/InvoicePage" )
const WalletPage = () => import( "@/pages/WalletPage" )
const WithdrawalCurrencyPage = () => import( "@/pages/WithdrawalCurrencyPage" )
const WithdrawalCurrencyGatewayPage = () => import( "@/pages/WithdrawalCurrencyGatewayPage" )
const WithdrawalCurrencySwiftPage = () => import( "@/pages/WithdrawalCurrencySwiftPage" )
const WithdrawalCurrencySepaPage = () => import( "@/pages/WithdrawalCurrencySepaPage" )
const DepositCurrencyPage = () => import( "@/pages/DepositCurrencyPage" )
const DepositCurrencySepaPage = () => import( "@/pages/DepositCurrencySepaPage" )
const DepositCurrencySwiftPage = () => import( "@/pages/DepositCurrencySwiftPage" )
const DepositCurrencyGatewayPage = () => import( "@/pages/DepositCurrencyGatewayPage" )
const ExchangePage = () => import( "@/pages/ExchangePage" )
const InternalMovementPage = () => import( "@/pages/InternalMovementPage" )
const AccountLoginPage = () => import( "@/pages/AccountLoginPage" )
const AccountRegistrationPage = () => import( "@/pages/AccountRegistrationPage" )
const AccountRecoverPasswordPage = () => import( "@/pages/AccountRecoverPasswordPage" )
const AccountHome = () => import( "@/pages/AccountHome" )
const InvoiceOrderCoinpayPage = () => import( "@/pages/InvoiceOrderCoinpayPage" )
const InvoiceOrderCryptoPage = () => import( "@/pages/InvoiceOrderCryptoPage" )
const HistoryPage = () => import( "@/pages/HistoryPage" )
const SettingsPage = () => import( "@/pages/SettingsPage" )
const WalletGroupPage = () => import( "@/pages/WalletGroupPage" )
const SettingsTelegramPage = () => import( "@/pages/SettingsTelegramPage" )
const DashboardPage = () => import( "@/pages/DashboardPage" )
const FeesAndLimitsPage = () => import( "@/pages/FeesAndLimits.vue" )

const routes = [
  {
    path: "/",
    redirect: {
      name: "AccountLoginPage"
    }
  },

  {
    path: "/login",
    name: "AccountLoginPage",
    component: AccountLoginPage
  },

  {
    path: "/registration",
    name: "AccountRegistrationPage",
    component: AccountRegistrationPage
  },

  {
    path: "/recover-password",
    name: "AccountRecoverPasswordPage",
    component: AccountRecoverPasswordPage
  },

  {
    path: "/account",
    name: "AccountHome",
    component: AccountHome,
    meta: {
      requiresAuth: true
    },
    redirect: {
      name: "DashboardPage"
    },
    children: [
      // { path: "dashboard", name: "DashboardPage", components: { default: WalletPage, ExchangePage, HistoryPage } },
      {
        path: "dashboard",
        name: "DashboardPage",
        component: DashboardPage
      },

      {
        path: "settings",
        redirect: {
          name: "SettingsUserPage"
        },
        name: "SettingsPage",
        component: SettingsPage,
        children: [
          {
            path: "user",
            name: "SettingsUserPage",
            component: SettingsUserPage
          },
          {
            path: "security",
            name: "SettingsSecurityPage",
            component: SettingsSecurityPage
          },
          {
            path: "api",
            name: "SettingsApiPage",
            component: SettingsApiPage
          },
          {
            path: "telegram",
            name: "SettingsTelegramPage",
            component: SettingsTelegramPage
          }
        ]
      },
      
      {
        path: "wallet",
        redirect: {
          name: "WalletPage"
        },
        name: "WalletGroupPage",
        component: WalletGroupPage,
        children: [
          {
            path: "balance",
            name: "WalletPage",
            component: WalletPage
          },

          {
            path: "fees-and-limits",
            name: "FeesAndLimitsPage",
            component: FeesAndLimitsPage
          },
        ]
      },

      {
        path: "invoice",
        name: "InvoicePage",
        component: InvoicePage
      },

      {
        path: "withdrawal",
        meta: {
          operation: "withdrawal"
        },
        name: "WithdrawalCurrencyPage",
        component: WithdrawalCurrencyPage,
        redirect: {
          name: "WalletPage"
        },
        children: [
          {
            path: "GATEWAY/:currency",
            meta: {
              method: "GATEWAY"
            },
            name: "WithdrawalCurrencyGatewayPage",
            component: WithdrawalCurrencyGatewayPage
          },
          {
            path: "SWIFT/:currency",
            meta: {
              method: "SWIFT"
            },
            name: "WithdrawalCurrencySwiftPage",
            component: WithdrawalCurrencySwiftPage
          },
          {
            path: "SEPA/:currency",
            meta: {
              method: "SEPA"
            },
            name: "WithdrawalCurrencySepaPage",
            component: WithdrawalCurrencySepaPage
          },
          {
            path: "INTERNAL-MOVEMENT/:currency",
            meta: {
              method: "INTERNAL-MOVEMENT"
            },
            name: "InternalMovementPage",
            component: InternalMovementPage
          },
        ]
      },

      {
        path: "deposit",
        meta: {
          operation: "deposit"
        },
        name: "DepositCurrencyPage",
        component: DepositCurrencyPage,
        redirect: {
          name: "WalletPage"
        },
        children: [
          {
            path: "GATEWAY/:currency",
            meta: {
              method: "GATEWAY"
            },
            name: "DepositCurrencyGatewayPage",
            component: DepositCurrencyGatewayPage
          },
          {
            path: "SWIFT/:currency",
            meta: {
              method: "SWIFT"
            },
            name: "DepositCurrencySwiftPage",
            component: DepositCurrencySwiftPage
          },
          {
            path: "SEPA/:currency",
            meta: {
              method: "SEPA"
            },
            name: "DepositCurrencySepaPage",
            component: DepositCurrencySepaPage
          }
        ]
      },

      {
        path: "exchange",
        name: "ExchangePage",
        component: ExchangePage
      },

      {
        path: "history",
        name: "HistoryPage",
        component: HistoryPage
      }
    ]
  },

  {
    path: "/invoice/:order_id",
    name: "InvoiceOrderPage",
    component: InvoiceOrderPage,
    children: [
      {
        path: "CRYPTO/:currency",
        name: "InvoiceOrderCRYPTOPage",
        component: InvoiceOrderCryptoPage
      },
      {
        path: "COINPAY",
        name: "InvoiceOrderCOINPAYPage",
        component: InvoiceOrderCoinpayPage
      },
    ]
  },

  {
    path: "/404",
    name: "NotFoundPage",
    component: NotFoundPage
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: {
      name: "NotFoundPage"
    }
  }
]

if ( process.env.NODE_ENV !== "production" ) {
  routes.push( {
    path: "/UI",
    name: "UI",
    component: UI
  } )
}

const router = createRouter( {
  history: createWebHistory(),
  routes
} )

router.beforeEach( ( to ) => {
  if ( to.meta.requiresAuth && !localStorage.getItem( "isAuth" ) ) {
    return {
      name: "AccountLoginPage"
    }
  }
} )

export default router